import React, { FC } from 'react';
import { Link, graphql, PageProps } from 'gatsby';

import { Layout } from '~/components/layout';
import { Title } from '~/components/Title';
import { DiscountCode } from '~/components/page-components/DiscountCode';

type QueryData = {
  site: {
    siteMetadata: {
      config: {
        enableHighwayCode: boolean;
      };
    };
  };
};

const Home: FC<PageProps<QueryData>> = ({ data }) => {
  const enableHighwayCode = data.site.siteMetadata.config.enableHighwayCode;
  return (
    <Layout>
      <DiscountCode />

      {enableHighwayCode && <Link to="/highway-code">Get Started</Link>}
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        config {
          enableHighwayCode
        }
      }
    }
  }
`;
